<template>
  <div class="flex flex-col gap-y-0 lg:gap-y-6">
    <header
      class="
        bg-white
        h-auto
        py-5
        px-16
        flex flex-col
        lg:flex-row
        justify-between
        items-center
        gap-y-3
      "
    >
      <h1
        class="font-EffraM lg:font-EffraR text-textBgGrey text-2xl lg:text-base"
      >
        Patientèle
      </h1>
      <div>
        <div
          class="
            flex flex-col
            md:flex-row
            gap-x-4 gap-y-3
            items-start
            md:items-end
          "
        >
          <date-picker
            id="pickdate"
            style="padding: 0; margin: 0"
            input-class="h-29R m-0 p-0 w-full border-2 border-solid border-borderInput rounded px-1 font-EffraR text-graylabel placeholder-graylabel"
            placeholder="Dernière Visite"
            v-model="lastVisit"
            @input="dateOfLastVisit"
          >
            <span slot="icon-calendar">
              <img src="/svg/calendar_sm.svg" />
            </span>
          </date-picker>
          <dok-input
            size="md"
            d-placeholder="Search"
            :d-model.sync="search.term"
          >
            <template v-slot:leftIcon>
              <img src="/svg/trailing.svg" />
            </template>
          </dok-input>
        </div>
      </div>
    </header>

    <div class="w-full lg:w-11/96 lg:mx-auto p-5 flex flex-col gap-y-8">
      <template v-if="!loader">
        <!-- flex flex-col gap-y-3 -->
        <div class="bg-white p-2 rounded">
          <div
            class="
              hidden
              lg:grid
              grid-cols-4
              gap-x-3
              bg-dokBlue-lighterr
              h-56
              items-center
              px-4
              rounded-lg
              mb-2
            "
          >
            <div>
              <div class="flex justify-between items-center">
                <span class="font-EffraR text-base text-black">Prenom</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="cursor-pointer hover:text-dokBlue-dark"
                  :class="
                    sortBy.sortOrder === 'Z-a' ? 'text-dokBlue-ultra' : ''
                  "
                  @click="onSortBy('firstName')"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 15l-6-6-6 6"
                  />
                </svg>
              </div>
            </div>
            <div>
              <div class="flex justify-between items-center">
                <span class="font-EffraR text-base text-black">Nom</span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                  class="cursor-pointer hover:text-dokBlue-dark"
                  :class="
                    sortBy.sortOrder === 'A-z' ? 'text-dokBlue-ultra' : ''
                  "
                  @click="onSortBy('lastName')"
                >
                  <path
                    stroke="currentColor"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M18 15l-6-6-6 6"
                  />
                </svg>
              </div>
            </div>
            <div>
              <div class="flex justify-between items-center">
                <span class="font-EffraR text-base text-black"
                  >Dernière Visite
                </span>
              </div>
            </div>
            <div class="font-EffraR text-base text-black flex justify-end">
              <a
                :href="`https://api.dok.ma/api/v1/users/patients?download=true&token=${$auth.getToken()}`"
                target="_blank"
                class="
                  w-100
                  transition
                  duration-150
                  ease-in-out
                  hover:bg-dokBlue-ultra hover:text-white
                  button-lg
                  px-2
                  py-1
                  bg-white
                  text-dokBlue-ultra
                  border-2 border-solid border-dokBlue-ultra
                  rounded
                  font-EffraR
                  cursor-pointer
                "
              >
                Exporter
              </a>
              <!-- <span class="sr-only">Actions</span> -->
            </div>
          </div>
          <!-- List Patients -->
          <div class="flex flex-col gap-y-4" id="ListItems">
            <div
              class="
                grid grid-cols-1
                lg:grid-cols-4
                gap-x-3 gap-y-3
                bg-transparent
                h-auto
                lg:h-56
                items-center
                px-4
                py-5
                lg:py-0
              "
              style="border: solid 1px #e7ecf2"
              v-for="(user, i) in patients"
              :key="i"
            >
              <div class="flex flex-row items-center gap-x-2">
                <img
                  :src="$checkAvatar(user.image)"
                  onerror="javascript:this.src='/no-avatar.png'"
                  class="
                    h-12
                    w-12
                    rounded-full
                    object-cover
                    bg-grayfoot
                    border border-solid border-gray-200
                  "
                  :alt="`${user.firstName} ${user.lastName}`"
                />
                <div class="flex flex-row gap-x-1 flex-1">
                  <span class="font-EffraR text-black text-lg">
                    {{ user.firstName }}
                  </span>
                </div>
              </div>
              <div class="hidden lg:flex flex-col">
                <span class="font-EffraR text-black text-lg">
                  {{ user.lastName }}
                </span>
              </div>
              <div class="flex flex-row justify-end lg:justify-start">
                <span class="font-EffraR text-black text-lg">
                  {{
                    user.lastConsultation && user.lastConsultation.endedAt
                      ? moment()(user.lastConsultation.endedAt).format(
                          "DD/MM/YYYY"
                        )
                      : "--"
                  }}
                </span>
              </div>
              <div class="flex justify-end">
                <dok-button
                  size="md"
                  :rounded="true"
                  bg="none"
                  custom-class="px-5 lg:px-3 hover:bg-dokBlue-lighter hover:text-dokBlue-dark flex items-center gap-x-2"
                  @click.native="
                    () => {
                      $router.push(`/patient/${user._id}?nav=carnet`);
                    }
                  "
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    fill="none"
                    viewBox="0 0 24 24"
                  >
                    <path
                      fill="currentColor"
                      d="M17 2v2h3c.552 0 1 .448 1 1v16c0 .552-.448 1-1 1H4c-.552 0-1-.448-1-1V5c0-.552.448-1 1-1h3V2h10zM7 6H5v14h14V6h-2v2H7V6zm6 5v2h2v2h-2.001L13 17h-2l-.001-2H9v-2h2v-2h2zm2-7H9v2h6V4z"
                    />
                  </svg>

                  Consulter le DMP
                </dok-button>
              </div>
            </div>
          </div>
          <!-- Null Data patients -->
          <div
            v-if="!patients.length"
            class="px-8 py-3 w-full flex justify-center items-center"
          >
            <img src="/svg/null_data.svg" class="max-h-300 xl:max-h-500" />
          </div>
          <!--Load More-->
          <template v-if="loadMoreData">
            <div
              v-for="(ix, index) in Array(5)"
              :key="`loader-${index}`"
              class="
                grid grid-cols-1
                lg:grid-cols-4
                gap-x-0 gap-y-3
                bg-transparent
                h-auto
                lg:h-56
                items-center
                px-0
                rounded-lg
                shadow
              "
            >
              <!---- Full Name --->
              <div
                class="
                  bg-graydok
                  h-full
                  animate-pulse
                  font-EffraM
                  text-lg text-black
                "
              >
                &ensp;
              </div>
              <!---- Date --->
              <div
                class="bg-graydok h-full animate-pulse font-EffraR text-base"
                style="color: #868c92"
              >
                &ensp;
              </div>
              <!---- Consultation Category --->
              <div
                class="
                  bg-graydok
                  h-full
                  animate-pulse
                  flex flex-row
                  items-center
                  gap-x-2
                "
              >
                &ensp;
              </div>
              <!---- Consultation Status Appointment --->
              <div class="bg-graydok h-full animate-pulse font-EffraR">
                &ensp;
              </div>
            </div>
          </template>
          <div class="flex justify-center items-center mt-3">
            <dok-button
              bg="trans"
              size="lg"
              custom-class="w-full lg:w-336 transition duration-150 ease-in-out hover:bg-dokBlue-ultra hover:text-white"
              @click.native="onGetPatients(true)"
              v-if="
                patients.length &&
                patients.length !== paginate.count &&
                !loadMoreData
              "
            >
              Voir Plus
            </dok-button>
          </div>
        </div>
      </template>
      <template v-if="loader">
        <div class="w-300 mx-auto px-5">
          <div class="p-5 px-8 flex justify-center gap-x-8">
            <div
              class="
                bg-dokBlue-light
                w-8
                h-8
                rounded-full
                animate-bounce
                blue-circle
              "
            ></div>
            <div
              class="
                bg-rosDok-ultra
                w-8
                h-8
                rounded-full
                animate-bounce
                red-circle
              "
            ></div>
            <div
              class="
                bg-dokBlue-ultra
                w-8
                h-8
                rounded-full
                animate-bounce
                green-circle
              "
            ></div>
          </div>
        </div>
      </template>
    </div>

    <!-- Add Patient -->
    <add-patient :active-modal="modalShow" :exit-modal="exitModal" />
  </div>
</template>

<script>
import { cloneDeep } from "lodash";
import moment from "moment";
import { EventBus } from "@/event-bus";
const AddPatient = () => import("./AddPatient");
const loader = () => import("@/views/global-components/loader");
const modal = () => import("@/views/global-components/modal");

import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

export default {
  data() {
    return {
      loader: true,
      modalShow: false,
      showMore: true,
      loaderSearch: false,
      oldData: [],
      patients: [],
      searchUsers: [],
      lastVisit: new Date(),
      loadMoreData: false,
      sortBy: {
        sortBy: "firstName",
        sortOrder: "A-z",
      },
      search: {
        attr: "",
        term: "",
        state: null,
        collaborator: "",
        page: 1,
      },
      paginate: {
        page: 1,
        count: 0,
      },
    };
  },
  components: {
    AddPatient,
    loader,
    modal,
    DatePicker,
  },
  computed: {
    users: {
      get() {
        return this.$store.getters["user/users"];
      },
      set(array) {},
    },
  },
  watch: {
    sortBy: {
      deep: true,
      handler(newVal) {
        this.onGetPatients();
      },
    },
    users(newVal, oldVal) {
      let clonedValue = cloneDeep(newVal);

      clonedValue.unshift({
        firstName: "Tout",
        lastName: "",
        role: "USER",
        _id: "",
      });

      this.searchUsers = clonedValue;
    },
    "search.term"(newVal, oldVal) {
      if (newVal !== oldVal && newVal.length > 2) {
        this.search.page = 1;
        this.onSearch();
      }
      if (!newVal) this.patients = this.oldData;
    },
    "search.page"(newVal, oldVal) {
      this.onSearch();
    },
    "search.attr"(newVal, oldVal) {
      this.onSearch();
    },
    "search.state"(newVal, oldVal) {
      this.onSearch();
    },
    "search.collaborator"(newVal, oldVal) {
      this.onSearch();
    },
  },
  mounted() {
    EventBus.$on("on_success_add_new_patient", (patient) => {
      this.patients.unshift(patient);
      this.modalShow = false;
    });

    EventBus.$on("Create_New_Patient", () => {
      this.modalShow = true;
      EventBus.$emit("clearFormClient", {});
    });
  },
  async created() {
    this.onGetPatients();
  },
  methods: {
    async onGetPatients(more = false) {
      if (!more) {
        this.loader = true;
      }

      if (more) {
        this.loadMoreData = true;
        this.paginate.page++;
      }
      await this.$store.dispatch("client/GET_LIST", {
        onData: ({ ok, data, count }) => {
          if (ok) {
            this.patients = !more ? data : [...this.patients, ...data];
            this.paginate.count = count;

            if (more) {
              return (this.loadMoreData = false);
            }
          }

          if (!more) {
            this.loader = false;
          }
        },
        search: this.search || {},
        sortBy: this.sortBy || {},
        page: this.paginate || {},
      });
    },
    onSortBy(type) {
      this.sortBy.sortBy = type;

      if (this.sortBy.sortOrder === "A-z") {
        this.sortBy.sortOrder = "Z-a";
      } else {
        this.sortBy.sortOrder = "A-z";
      }
    },
    exitModal() {
      this.modalShow = false;
    },
    onSearch() {
      this.loaderSearch = true;
      this.$store.dispatch("client/GET_LIST", {
        onData: this.onDataSearch,
        search: this.search,
      });
    },
    onDataSearch({ ok, data, count }) {
      if (!this.oldData.length) this.oldData = this.patients;
      if (ok) {
        this.patients = data;
        this.loaderSearch = false;
      }
    },
    moment() {
      return moment;
    },
    dateOfLastVisit(newVal) {
      this.lastVisit = moment(newVal).toDate();
    },
    downloadPatients() {
      window.location = `https://api.dok.ma/api/v1/patients?download=true&token=${auth.getToken()}`;
    },
  },
};
</script>

<style lang="scss" scoped>
.picker-res {
  left: -100px;
  top: 0;
} // Phone

@media (min-width: 640px) {
  .picker-res {
    left: -58px;
  } // Tablet
}

@media (min-width: 1024px) {
  .picker-res {
    left: -55px;
  } // Laptop
}

@media (min-width: 1280px) {
  .picker-res {
    left: -56px;
  } // Desktop
}
</style>
